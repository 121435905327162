@tailwind base;

.form-label {
  @apply text-xs  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider;
}

.table-optimized {
  @apply text-xxxs sm:text-xs lg:text-base md:text-sm px-1;
}

.global-form-label {
  @apply absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none transition-all duration-200 z-10;
}

.global-input {
  @apply w-full px-6 pt-7 pb-2 border border-gray-300 rounded-md bg-white;
}

.form-input-profile {
  @apply w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light text-base placeholder:text-xs;
}

.campaign-input {
  @apply w-full text-base md:text-lg p-4 md:p-6 md:mr-3 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light placeholder:text-sm;
}

.campaign-tooltip {
  @apply tooltip tooltip-left mr-3 mb-2 tooltip-primary;
}

.settings-tooltip {
  @apply tooltip  mr-3  tooltip-primary text-orange-500 ml-2 mb-4 tooltip-bottom;
}

.campaign-label {
  @apply text-left text-xs font-bold mb-1;
}

.btn-small {
  @apply btn  btn-primary btn-xs ml-2 md:text-xs text-xxxs;
}

body {
  @apply font-sans;
}

@tailwind components;
@tailwind utilities;

.form-group {
  @apply relative;
}

.container {
  width: 100%;
  margin-top: 200px;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;
}

.table th:first-child {
  position: unset;
  left: unset;
  z-index: unset;
}

.custom-dropdown {
  z-index: 999; /* Set your desired z-index value here */
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
}

.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 100;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

.custom-datetime .form-control {
  width: 100%;
}

.tooltip::before {
  white-space: pre-wrap;
}

@media (max-width: 768px) {
  .headline-mobile {
    font-size: 3rem;
    /* Adjust this value based on your desired mobile font size */
  }
}

body {
  margin: 0;
  background: #f1f2f5;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 2s infinite;
}

.custom-zindex {
  z-index: 5000;
}

.custom-zindex-less {
  z-index: 4000;
}

.form-prefix {
  padding-right: 8px;
  font-weight: bold;
}

.form-input-profile {
  padding-left: 8px; /* Adjust padding to make space for @ symbol */
}

/* Add this to your CSS file */
.draftjs-content h1 {
  font-size: 2em;
  font-weight: bold;
}
.draftjs-content h2 {
  font-size: 1.5em;
  font-weight: bold;
}
.draftjs-content h3 {
  font-size: 1.17em;
  font-weight: bold;
}
.draftjs-content ul {
  list-style-type: disc;
  padding-left: 20px;
}
.draftjs-content ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.rich-text-content {
  white-space: pre-wrap;
  word-break: break-word;
}

.rich-text-content p {
  margin-bottom: 1em;
}

.rich-text-content h1,
.rich-text-content h2,
.rich-text-content h3,
.rich-text-content h4,
.rich-text-content h5,
.rich-text-content h6 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.rich-text-content ul,
.rich-text-content ol {
  padding-left: 1.5em;
  margin-bottom: 1em;
}

.rich-text-content li {
  margin-bottom: 0.5em;
}

.custom-tooltip {
  max-width: 300px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Handle disabled state with Tailwind classes */
select:disabled {
  @apply bg-gray-200 !important;
  opacity: 1 !important;
}

/* Ensure proper background color even with Android's forced styling */
select option {
  @apply bg-white;
}

/* Style date inputs consistently across devices */
input[type='date'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent !important;
  color: inherit;
  border: 1px solid #e2e8f0; /* or whatever border color you're using */
  padding: 0.5rem;
}

/* Remove default calendar icon on Chrome */
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* Ensure consistent styling on iOS */
input[type='date'],
input[type='date']:focus {
  -webkit-text-fill-color: currentColor;
  opacity: 1;
}

/* Override Android select styling */
select {
  @apply bg-white appearance-none;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-color: white !important;
  /* Custom dropdown arrow using Tailwind's theme colors */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right 0.5rem center !important;
  background-size: 1.5em !important;
}

/* Handle disabled state with Tailwind classes */
select:disabled {
  @apply bg-gray-200 !important;
  opacity: 1 !important;
}

/* Ensure proper background color even with Android's forced styling */
select option {
  @apply bg-white;
}

/* Style date inputs consistently */
input[type='date'] {
  @apply bg-white appearance-none;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-color: white !important;
}

/* Remove default calendar icon on Chrome */
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* Add these new styles for the CountryDropdown */
.global-input.react-country-dropdown {
  @apply h-[52px] pt-7 pb-2;
  padding-top: 1.75rem !important; /* Force top padding */
  padding-bottom: 0.5rem !important; /* Force bottom padding */
}

/* Ensure the dropdown arrow stays visible */
select.global-input.react-country-dropdown {
  background-position: right 1rem center !important;
}

/* Style for select inputs in payment form */
.global-input-select {
  @apply w-full px-6 pt-7 pb-2 border border-gray-300 rounded-md text-base;
  height: 52px !important;
  padding-top: 1.75rem !important;
  padding-bottom: 0.5rem !important;
  background-position: right 1rem center !important;
}

/* When select has a value or is focused, move the label up */
select:focus + label,
select:not([value='']):not([value='none']) + label {
  @apply top-0 -translate-y-0 text-sm pt-1;
}

/* Style for disabled select inputs */
select:disabled + label {
  @apply text-gray-400;
}

/* Ensure proper stacking context */
.form-group {
  @apply relative;
}

.global-input {
  @apply relative z-[2]; /* Ensure input is above label */
}

/* Force label to stay up when LastPass is active */
.global-input[data-lastpass-filled='true'] ~ .global-form-label,
.global-input[data-lastpass-icon-root] ~ .global-form-label {
  @apply top-0 -translate-y-0 text-sm pt-1 !important;
}

@keyframes slowPulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.825; /* Dialed back intensity compared to the default (0.5) */
  }
}

.animate-slow-pulse {
  animation: slowPulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes glow {
  0%,
  100% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinAndRipple {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(-15deg);
  }
  15% {
    transform: rotate(360deg) scale(1.1);
  }
  20% {
    transform: rotate(350deg) scale(1);
  }
  25% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 140, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(255, 140, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 140, 0, 0);
  }
}

.teds-logo {
  transition: all 0.3s ease;
  animation: float 6s ease-in-out infinite, glow 3s ease-in-out infinite, spinAndRipple 10s ease-in-out infinite, ripple 10s ease-in-out infinite;
  border-radius: 50%;
}

.teds-logo:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 0 25px rgba(255, 140, 0, 0.8)) brightness(1.1);
  animation-play-state: paused;
}

a {
  -webkit-tap-highlight-color: transparent;
}
