/* editor-styles.css */

/* Headings */
.rdw-editor-main h1 {
  font-size: 2em;
  font-weight: bold;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}

.rdw-editor-main h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}

.rdw-editor-main h3 {
  font-size: 1.17em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
}

.rdw-editor-main h4 {
  font-size: 1em;
  font-weight: bold;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}

.rdw-editor-main h5 {
  font-size: 0.83em;
  font-weight: bold;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}

.rdw-editor-main h6 {
  font-size: 0.67em;
  font-weight: bold;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}

/* Paragraph */
.rdw-editor-main p {
  margin-top: 1em;
  margin-bottom: 1em;
}

/* Lists */
.rdw-editor-main ul,
.rdw-editor-main ol {
  padding-left: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.rdw-editor-main li {
  margin-bottom: 0.5em;
}

/* Inline styles */
.rdw-editor-main strong {
  font-weight: bold;
}

.rdw-editor-main em {
  font-style: italic;
}

.rdw-editor-main u {
  text-decoration: underline;
}

.rdw-editor-main s {
  text-decoration: line-through;
}

/* Blockquote */
.rdw-editor-main blockquote {
  border-left: 5px solid #ccc;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1em;
  color: #666;
  font-style: italic;
}

/* Code block */
.rdw-editor-main pre {
  background-color: #f4f4f4;
  padding: 1em;
  border-radius: 4px;
  overflow-x: auto;
}

/* Links */
.rdw-editor-main a {
  color: #0000ee;
  text-decoration: underline;
}

/* Editor wrapper */
.rdw-editor-wrapper {
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 2px;
}

/* Editor toolbar */
.rdw-editor-toolbar {
  border-bottom: 1px solid #f1f1f1;
  padding: 6px 5px 0;
  border-radius: 2px;
  margin-bottom: 5px;
}

/* Editor content area */
.rdw-editor-main {
  min-height: 100px;
  padding: 0 5px;
}
